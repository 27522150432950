module stratos.common.services {

    export class authenticationInterceptorService implements ng.IHttpInterceptor {

        static $inject = ['$q', '$location', '$injector', 'localStorageService', 'adalAuthenticationService'];

        private baseUrl: Array<string> = window.location.href.split("/", 3);
        private baseUrlNoPort: Array<string> = this.baseUrl[2].split(":", 2);
        private serviceBase: string = this.baseUrl[0] + "//" + this.baseUrlNoPort[0];
        private clientId: string;

        constructor(
            private $q: ng.IQService, 
            private $location: ng.ILocationService,
            private $injector: ng.auto.IInjectorService,
            private localStorageService: ng.local.storage.ILocalStorageService,
            private adalAuthenticationService: any//adal.AuthenticationContext
        ) {
                // increase the timeout used for token renewal.. for some reason this variable is a string in the underlying library
                // _adal is initialised by the previous call.. we can get to its 'CONSTANTS'
                console.log("Updating adal configuration");
                var authenticationContextAsAny = <any> AuthenticationContext;
                
                if (authenticationContextAsAny.prototype) 
                {
                    if (authenticationContextAsAny.prototype._singletonInstance) 
                    {
                        if (authenticationContextAsAny.prototype._singletonInstance.CONSTANTS) 
                        {
                            authenticationContextAsAny.prototype._singletonInstance.CONSTANTS.LOADFRAME_TIMEOUT = '20000';
                        }
                    }
                }
        }


        //this is a copy of securityService.getUser(); that retrieves user data from local storage
        //could not inject securityService here because of circular reference: $http <- securityService <- authenticationInterceptorService <- $http
        private getUser = (): models.user => { 

            var user = new models.user();
            var aadUserName = null;

            if (this.adalAuthenticationService && this.adalAuthenticationService.userInfo && this.adalAuthenticationService.userInfo.userName)
            {
                aadUserName = this.adalAuthenticationService.userInfo.userName;
            }

            var userData = this.localStorageService.get<any>('userData');

            if(userData && aadUserName)
            {
                user = userData[aadUserName] || user;
            }

            return user;
        }

        private appendTransform = (defaults, transform) => {

            defaults = angular.isArray(defaults) ? defaults : [defaults];

            return defaults.concat(transform);
        }
        
        private isAnonymousEndpoint = (endpoints, url) => {
            endpoints = endpoints || [];

            for (var i = 0; i < endpoints.length; i++) {
                if (url.indexOf(endpoints[i]) > -1) {
                    return true;
                }
            }

            return false;
        }

        private injectTenantId = (data) => {        
            var user = this.getUser();

            var tenantId = (user && user.UiActiveTenant) ? user.UiActiveTenant.TenantId : null;

            //console.log('authenticationInterceptorService.injectTenantId', tenantId);

            data = data || { TenantId: null };
            if(typeof data === "string") {
                //if data is string convert to JS array
                data = JSON.parse(data);
            }

            data['TenantId'] = data['TenantId'] || tenantId;
            console.log(data);

            return JSON.stringify(data);
        }
       
        request = (config: any): any => {
            var _self = this;
            var $http = _self.$injector.get('$http');

            if (((config.url.lastIndexOf(_self.serviceBase, 0) === 0))
            ) {
                //console.log('authenticationInterceptorService.injectTenantId', config.url);

                var adalConfig = _self.adalAuthenticationService.config;

                //if the url is in the anonymousEndpoints list, doesn't inject TenantId automatically
                if(!_self.isAnonymousEndpoint(adalConfig.anonymousEndpoints, config.url))
                {
                    config.transformRequest = _self.appendTransform($http.defaults.transformRequest, _self.injectTenantId);
                }
            }

            return config;
        }

        responseError = (rejection: any): ng.IPromise<any> => {
            if (rejection.status === 401) {
                this.$location.path('/');
            }
            return this.$q.reject(rejection);
        }
    } 

    var app = angular.module('stratos.common');
    app.service('authenticationInterceptorService', authenticationInterceptorService);
}